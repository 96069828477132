import React, { Component } from 'react';

class Differential extends Component {
    render() {
        return (
            <div className='differential-card'>
                {this.props.icon}
                <p className="differential-title">{this.props.title}</p>
                <p className="differential-description">{this.props.description}</p>
            </div>
        )
    }
    
}

export default Differential;