import React from 'react';
import ProjectOne from "../../elements/project/projectOne";


const Project = () => {
    return (
        <div className="rn-portfolio-area rn-section-gap portfolio-style-1" id="portfolio">
            <div className="container">
                <div className="row mb--10">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <h3 className="title">Nossos Projetos</h3>
                        </div>
                    </div>
                </div>
                <ProjectOne />
            </div>
        </div>
    )
}
export default Project
