import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Carousel } from 'react-responsive-carousel';
import Blog from "./blog";
import BlogMobile from "./blogMobile";

const BlogPost = () => {
    const blogQueryData = useStaticQuery(graphql`
        query BlogListQuery {
            allMarkdownRemark (limit: 5, sort: {order: ASC, fields: frontmatter___date}) {
                edges {
                    node {
                        fields {
                            slug
                        }
                        excerpt(pruneLength: 83, format: PLAIN, truncate: true)
                        frontmatter {
                            author {
                                name
                            }
                            title
                            postnumber
                            date(formatString: "DD/MM/YYYY")
                            format
                            category
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 767, maxHeight: 767, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        presentationWidth
                                        presentationHeight
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    `);
    const blogs = blogQueryData.allMarkdownRemark.edges;

    return (
        <div className="rn-post-area rn-section-gap" id="news">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-xl-12">
                        <div className="section-title">
                            <h3 className="title">Blog da SAPÊ</h3>
                        </div>
                    </div>
                </div>
                
                <div className="row row--25">
                    <div className="col-lg-12 col-xl-8 offset-xl-2">
                        <div className="blog-list-wrapper desktop-only">
                            {blogs.map(blog => (
                                <div className="single-blog" key={blog.node.fields.slug}>
                                    <Blog
                                        image={blog.node.frontmatter.image.childImageSharp.fluid}
                                        title={blog.node.frontmatter.title}
                                        date={blog.node.frontmatter.date}
                                        category={blog.node.frontmatter.category}
                                        author={blog.node.frontmatter.author.name}
                                        path={blog.node.fields.slug}
                                    />
                                </div>
                            ))}
                        </div>
                        <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} className="mobile-only">
                            {blogs.map(blog => (
                                <div className="single-blog" key={blog.node.fields.slug}>
                                    <BlogMobile
                                        image={blog.node.frontmatter.image.childImageSharp.fluid}
                                        title={blog.node.frontmatter.title}
                                        date={blog.node.frontmatter.date}
                                        category={blog.node.frontmatter.category}
                                        author={blog.node.frontmatter.author.name}
                                        path={blog.node.fields.slug}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default BlogPost;
