import React from "react";
import Modal from 'react-modal';
import { isMobile } from 'react-device-detect';

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Banner from "../components/homedefault/banner";
import Slider from "../components/homedefault/slider";
import Project from "../components/homedefault/project";
import Chooseus from "../components/homedefault/chooseus";
import ServicesNew from "../components/homedefault/servicesNew";
// import Skill from "../components/homedefault/skill";
import Testimonial from "../components/homedefault/testimonial";
import Advertising from "../components/homedefault/advertising";
// import Service from "../components/homedefault/service";
import Differentials from "../components/homedefault/differentials";
import Method from "../components/homedefault/method";
import BlogPost from "../components/blogPost";
import Contact from "../elements/contact/contact";
// import Progressbar from "../elements/progressbar/progressbar";
// import Video from "../components/video";
import FloatingWhatsApp from 'react-floating-whatsapp'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar from '../assets/images/avatar.jpeg';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(5, 5, 5, 0.75)',
    zIndex: 999
  },
  content: {
    position: 'absolute',
    top: isMobile ? '20px' : '70px',
    left: isMobile ? '40px' : '200px',
    right: isMobile ? '40px' : '200px',
    bottom: isMobile ? '20px' : '120px',
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '0px',
    outline: 'none',
    overflow: 'hidden',
    border: 'none',
    padding: '0',
    backgroundImage: 'url(https://sape.s3.amazonaws.com/imgs/popup.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  },
};

Modal.setAppElement('#___gatsby');


const IndexPage = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  
  addClickEvent()
  return (
  <Layout>
    <SEO title="Sapê Arquitetos" />
    <Slider />
    <Differentials/>
    {/* <Banner /> */}
    <Project />
    <Chooseus />
    <ServicesNew />
    <Method/>
    {/* <Progressbar /> */}
    {/* <Skill /> */}
    {/* <Video /> */}
    {/* <Service /> */}
    <Contact />
    <Testimonial />
    <BlogPost />
    <FloatingWhatsApp
      phoneNumber="5511972656058"
      accountName="Sapê Arquitetos"
      notification
      notificationDelay={60000} // 1 minute
      statusMessage="Estamos felizes em te conhecer"
      chatMessage="Olá, bom te ver por aqui. 
      Como podemos ajudar no seu projeto?"
      avatar={avatar}
    />
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={true}
    >
      <Advertising closeModal={()=>closeModal()}/>
    </Modal>
    <ToastContainer/>
  </Layout>
)}

export default IndexPage;

async function addClickEvent() {
  try {
    await new Promise(r => setTimeout(r, 2000));

    const collection = document.getElementsByClassName("styles-module_buttonSend__3FZcz");
    const collection2 = document.getElementsByClassName("participate-button");
    
    let element1 = collection[0];
    let element2 = collection2[0];

    if (element1.addEventListener) {  // all browsers except IE before version 9
      element1.addEventListener("click", convertWhatsapp, false);
    } else {
      if (element1.attachEvent) {   // IE before version 9
        element1.attachEvent("click", convertWhatsapp);
      }
    }

    if (element2.addEventListener) {  // all browsers except IE before version 9
      element2.addEventListener("click", convertPopup, false);
    } else {
      if (element2.attachEvent) {   // IE before version 9
        element2.attachEvent("click", convertPopup);
      }
    }
  } catch(err) {
    console.error(err)
  }
}

function convertWhatsapp() {
  var callback = function () {}
  window.gtag('event', 'conversion', {'send_to': 'AW-10838478638/yK09CNDx8qsDEK6emLAo', 'event_callback': callback})
}

function convertPopup() {
  var callback = function () {}
  window.gtag('event', 'conversion', {'send_to': 'AW-10838478638/NQ8kCLqcsr4DEK6emLAo', 'event_callback': callback})
}

