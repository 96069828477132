import React from 'react';
import {Link} from 'gatsby'
import Image from "../image";
import Img from 'gatsby-image'


const Projectcard = ({image, id, title, category, column, size, location }) => {
    let projectImg;
    if(image) {
        if(image.fixed && typeof image.fixed !== 'function'){
            projectImg = <Img fixed={image.fixed} alt={title}/>;
        } else if(image.fluid) {
            projectImg = <Image fluid={image.fluid} alt={title}/>
        } else {
            projectImg = <img src={image.src} alt={title}/>
        }
    }
    
    return (
        <div className="portfolio">
            <div className="thumbnail">
                <Link to={`/project/${id}`}>
                    {projectImg}
                </Link>
                <div className='image-footer'>
                    <div>
                        <p className='image-description-text'>{title}</p>
                        <p className='image-description-text'>{size} / {location}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projectcard;