import React from 'react';

const Chooseus = () => {
    return (
        <div className="rn-choose-us-area choose-us-style-01 rn-section-gapBottom choose-us-section" id="chooseus">
            <div className="wrapper">
                <div className="section-title">
                    <h3 className="title mb_lg--50 mb_md--50 mb_sm--40">Quem é a Sapê?</h3>
                </div>
                <div className="container">
                    <div className="image-group-3">
                        <div className="single-image">
                            <img className="img-two" alt="" src="https://sape.s3.amazonaws.com/imgs/os+2_1450x900.jpg"/>
                        </div>
                    </div> 
                    <div className='chooseus-person-detail-box top left'>
                        <p className='chooseus-person-detail-text title'>MARIANA TEALDI</p>
                        <p className='chooseus-person-detail-text'>Arquiteta e Urbanista pela PUC Campinas. Mestrado em Projeto e suas implicações urbanas pelo LADEUR (Laboratório de Desenho de Estratégias Urbano-Regionais) POSURB- ARQ (PUC Campinas). Focada em arquitetura humanizada, desenho técnico arquitetônico, de interiores, de mobiliário e cenográfico.</p>
                    </div>
                    <div className='chooseus-person-detail-box bottom right'>
                        <p className='chooseus-person-detail-text title'>VINÍCIUS PELLEGRINO</p>
                        <p className='chooseus-person-detail-text'>Arquiteto e Urbanista pela PUC Campinas. Pós Graduação em História da Arte: teoria e Crítica pelo Centro Universitário Belas Artes de São Paulo e em História da Arte como Expressão Social pela PUC São Paulo.</p>
                        <p className='chooseus-person-detail-text'>Focado em gerenciamento financeiro e de obras, representação artística, arquitetônica, de interiores, de mobiliário e cenografia.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Chooseus;
