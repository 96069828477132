import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { FaUserAlt } from "react-icons/fa";

const Slider = () => {
    const banenrQueryData = useStaticQuery (graphql`
        query CarouselDefaultQuery {
                homedefaultJson(id: {eq: "main-banner"}) {
                title    
                subtitle
                description
                buttonText
                bgImage {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920, maxHeight: 980) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationHeight
                            presentationWidth
                        }
                    }
                }
            },
            file(relativePath: {eq: "/static/images/test.jpeg"}) {
                childImageSharp {
                  fixed (quality: 100, width: 640, height: 750) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
        }
    `);

    // const Title = banenrQueryData.homedefaultJson.title;
    const BannerImages = banenrQueryData.homedefaultJson.bgImage.childImageSharp.fluid;

    return (
        <div className="rn-slider-area" id="home">
            <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/3.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/1_1905x930.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/2_1905x930.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/3_1905x930.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/4_1905x930.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/5_1905x930.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/6_1905x930.jpg" />
                </div>
                <div className="img-slider-box">
                    <img className="img-slider" alt="" src="https://sape.s3.amazonaws.com/imgs/7_1905x930.jpg" />
                </div>
            </Carousel>
        </div>
    )
}
export default Slider;