import React from 'react';
import { Link } from "gatsby";
import { slugify } from "../utils/utilityFunctions";
import Image from "../elements/image";
import Img from "gatsby-image";

const BlogMobile = ({ title, date, author, path, image, excerpt, category }) => {
    return (
        <Link to={`/${path}`}>
            <div className="content-block-mobile">
                <div className="post-thubnail">
                    {image && 
                        <Image fluid={image} alt={title}/>
                    }
                </div>
                <div className='image-footer'>
                    <div>
                        <p className='image-description-text'>{title && <h3 className="title">{title}</h3>}</p>
                        <p className='image-description-text'>
                            {date && <span className="date">{date}</span>} | {author && <span className="author">Por {author}</span>}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    )
}
export default BlogMobile
