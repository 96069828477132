import React from 'react';
import Differential from "../../elements/differential/differential";
import { FiClock } from "react-icons/fi";
import { TiPen } from "react-icons/ti";
import { RiCoinsLine } from "react-icons/ri";
import { Carousel } from 'react-responsive-carousel';

const Differentials = () => {
    return (
        <div className="rn-choose-us-area choose-us-style-01 rn-section-gapBottom" id="differentials">
            <div className="wrapper differentials-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="inner" style={{height:"750px"}}>
                                <div className="section-title">
                                    <h3 className="title mb_lg--50 mb_md--50 mb_sm--40">Por que a Sapê?</h3>
                                </div>
                                <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop className='mobile-only' preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                                    <Differential
                                        icon={<FiClock className="icon"/>}
                                        title={"Entregamos seu projeto em 30 dias"}
                                        description={"Nosso método de projeto é rápido, descomplicado e muito visual. Isso faz com que os fornecedores entendam e executem sua reforma com mais facilidade,"}
                                    />
                                    <Differential
                                        icon={<TiPen className="icon"/>}
                                        title={"Você participa do processo criativo"}
                                        description={"Sua casa tem que ter a sua cara. Vamos te ouvir e trocar referências sobre o que você gosta e não gosta. Assim seu projeto vai se tornar um lugar inspirador para você!"}
                                    />
                                    <Differential
                                        icon={<RiCoinsLine className="icon"/>}
                                        title={"Entendemos o seu budget"}
                                        description={"Ninguém merece um projeto lindo mas que é financeiramente inviável. Iremos conversar sobre seu orçamento para cada etapa da obra antes de realizar o projeto."}
                                    />
                                </Carousel>
                                <div className="differential-row desktop-only">
                                    <Differential
                                        icon={<FiClock className="icon"/>}
                                        title={"Entregamos seu projeto em 30 dias"}
                                        description={"Nosso método de projeto é rápido, descomplicado e muito visual. Isso faz com que os fornecedores entendam e executem sua reforma com mais facilidade,"}
                                    />
                                    <Differential
                                        icon={<TiPen className="icon"/>}
                                        title={"Você participa do processo criativo"}
                                        description={"Sua casa tem que ter a sua cara. Vamos te ouvir e trocar referências sobre o que você gosta e não gosta. Assim seu projeto vai se tornar um lugar inspirador para você!"}
                                    />
                                    <Differential
                                        icon={<RiCoinsLine className="icon"/>}
                                        title={"Entendemos o seu budget"}
                                        description={"Ninguém merece um projeto lindo mas que é financeiramente inviável. Iremos conversar sobre seu orçamento para cada etapa da obra antes de realizar o projeto."}
                                    />
                                </div>
                                <div className='forms-btn-box' onClick={() => {window.open("https://docs.google.com/forms/d/1O-QpqMFeiA_W9KvRu5XeRjZ1HgQAD_ji2rx-vadHhKU/viewform?edit_requested=true", "_blank");}}>
                                    <p className='forms-btn-text'>RECEBA UM ORÇAMENTO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div> 
        </div>
    )
}
export default Differentials;