import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const ServicesNew = () => {
    return (
        <div className="rn-choose-us-area choose-us-style-01" id="services">
            <div className="wrapper">
                <div className="section-title">
                    <h3 className="title mb_lg--50 mb_md--50 mb_sm--40" style={{marginBottom: 64}}>Nossos Serviços</h3>
                </div>
                <div className="feature-list-centralizer">
                    <Carousel  showThumbs={false} showStatus={false} infiniteLoop className='mobile-only' preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                        <div className="single-feature">
                            <div className="service-img">
                                <img alt="" src="https://sape.s3.amazonaws.com/imgs/service2.jpeg"/>
                                <div className='overlay'/>
                                <h4 className="title">CONSULTORIA</h4>
                            </div>
                            <ul className="feature-list">
                                <li>Escolha de imóveis</li>
                                <li>Layout e mobiliário</li>
                                <li>Pintura e decorações</li>
                                <li>Iluminação</li>
                                <li>Paisagismo</li>
                            </ul>
                        </div>
                        <div className="single-feature">
                            <div className="service-img">
                                <img alt="" src="https://sape.s3.amazonaws.com/imgs/service1.jpeg"/>
                                <div className='overlay'/>
                                <h4 className="title">PROJETO DE ARQUITETURA</h4>
                            </div>
                            <ul className="feature-list">
                                <li>Reforma</li>
                                <li>Imóveis residenciais</li>
                                <li>Unidades comerciais</li>
                                <li>Eventos e cenografia</li>
                            </ul>
                        </div>
                    </Carousel>
                    <div className="feature-list-inner desktop-only">
                        <div className="single-feature">
                            <div className="service-img">
                                <img alt="" src="https://sape.s3.amazonaws.com/imgs/service2.jpeg"/>
                                <div className='overlay'/>
                                <h4 className="title">CONSULTORIA</h4>
                            </div>
                            <ul className="feature-list">
                                <li>Escolha de imóveis</li>
                                <li>Layout e mobiliário</li>
                                <li>Pintura e decorações</li>
                                <li>Iluminação</li>
                                <li>Paisagismo</li>
                            </ul>
                        </div>
                        <div className="single-feature">
                            <div className="service-img">
                                <img alt="" src="https://sape.s3.amazonaws.com/imgs/service1.jpeg"/>
                                <div className='overlay'/>
                                <h4 className="title">PROJETO DE ARQUITETURA</h4>
                            </div>
                            <ul className="feature-list">
                                <li>Reforma</li>
                                <li>Imóveis residenciais</li>
                                <li>Unidades comerciais</li>
                                <li>Eventos e cenografia</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}
export default ServicesNew;
