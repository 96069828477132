import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

const Advertising = ({closeModal}) => {
    const { register, handleSubmit, errors } = useForm({
		mode: "onBlur"
	})

    const [value, setValue] = useState({
		name: '',
		email: ''
    });

    const [serverState, setServerState] = useState({
		submitting: false,
		status: null
    });

    const onChangeHandler = e => {
		setValue({ ...value, [e.target.name]: e.target.value })
	}

    const onSubmit = (data, e) => {
		const form = e.target;
		setServerState({ submitting: true });

		axios({
			method: "post",
			url: "https://api.mailgun.net/v3/sapearquitetos.com.br/messages",
			auth: {
                username: 'api',
                password: "05dd099241792e83ba4834849409ff3c-054ba6b6-0d12fdfc"
            },
            params: {
                from: "Site Sapê <mailgun@sapearquitetos.com.br>",
                to: "sape.arquitetos@gmail.com",
                subject: "Novo lead para consultoria 20/06",
                text: `Nome: ${data.name} | Email: ${data.email}`
            },
		})
        .then(res => {})
        .catch(err => {
            handleServerResponse(false, err.response.data.error, form);
        });
        axios({
            method: "post",
            url: "https://api.mailgun.net/v3/sapearquitetos.com.br/messages",
            auth: {
                username: 'api',
                password: "05dd099241792e83ba4834849409ff3c-054ba6b6-0d12fdfc"
            },
            params: {
                from: "Site Sapê <mailgun@sapearquitetos.com.br>",
                to: data.email,
                subject: "Obrigado por se inscrever!",
                html: `<body class="bg-light">
                <div class="container">
                  <div class="card my-10">
                    <div class="card-body">
                      <div class="space-y-3">
                        <img alt="" src="https://sape.s3.amazonaws.com/imgs/sorteio.png"/>
                    </div>
                  </div>
                </div>
              </body>`
            },
        })
        .then(res2 => {
            var callback = function () {}
            toast("Obrigado por participar!", {type: "success"})
            closeModal()
        });
	}

    const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
			setValue({
				name: '',
				email: '',
				subject: '',
				message: ''
			})
		}
    };

    return (
        <div className="wrapper">
            <div className='close-area' onClick={()=>closeModal()}>
                <span className='close-x'>x</span>
            </div>
            <div className="container">
                <div className='col-6'>
                    <p className='text-1'>precisa de ajuda para deixar seu apê com a sua cara?</p>
                    <p className='text-2'>Cadastre seu e-mail conosco e concorra a um bate-papo gratuito com os arquitetos da SAPÊ.</p>
                    <p className='text-2'>Acompanhe a live do sorteio no dia 20/06 às 18 hrs.</p>
                </div>
                <div className='col-6'>
                    <form className='form' onSubmit={handleSubmit(onSubmit)}>
                        <div className='input-div'>
                            <label className='label-1' htmlFor="name">Seu nome:</label>
                            <input 
                                className='input-1'
                                type="text"
                                name="name"
                                id="name"
                                onChange={onChangeHandler}
                                ref={register({
                                    required: 'Nome completo é obrigatório',
                                })}
                            />
                            {errors.name && <span className="error">{errors.name.message}</span>}
                        </div>
                        <div className='input-div'>
                            <label className='label-1' htmlFor="email">Seu e-mail:</label>
                            <input 
                                className='input-1'
                                type="email"
                                name="email"
                                id="email"
                                onChange={onChangeHandler}
                                ref={register({
                                    required: 'Email é obrigatório',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "email inválido"
                                    }
                                })}
                            />
                            {errors.email && <span className="error">{errors.email.message}</span>}
                        </div>
                        <div className='button-row'>
                            <button 
                                className='participate-button' 
                                type="submit"
                            >
                                <span className='circle left'></span>
                                participar
                                <span className='circle right'></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Advertising;
