import React from 'react';
import MethodStep from "../../elements/method/methodStep";
import { AiOutlineControl } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { BiUserVoice } from "react-icons/bi";
import { Carousel } from 'react-responsive-carousel';

const Method = () => {
    return (
        <div className="rn-choose-us-area choose-us-style-01" id="method">
            <div className="wrapper" style={{marginTop:40}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="section-title">
                                    <h3 className="title mb_lg--50 mb_md--50 mb_sm--40">Método Sapê</h3>
                                    <p className="subtitle mb_lg--50 mb_md--50 mb_sm--40 method-subtitle">Em seis anos de escritório, desenvolvemos um método para descomplicar sua experiência com a Arquitetura.</p>
                                </div>
                                <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop className='mobile-only' preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                                    <MethodStep
                                        icon={<AiOutlineControl className="icon"/>}
                                        title={"Etapa Visual"}
                                        description={"Começamos seu projeto de uma maneira didática e participativa onde compreendemos seus problemas e necessidades e te apresentamos um resultado fácil de entender, através de imagens, modelos tridimensionais e referências. É nessa etapa que você visualiza a cara e as soluções do nosso projeto."}
                                    />
                                    <MethodStep
                                        icon={<FaCalendarAlt className="icon"/>}
                                        title={"Etapa Técnica"}
                                        description={"Com base no projeto aprovado por você, realizamos os desenhos técnicos de modo simples e assertivo com as informações fundamentais para que possamos orçar com precisão sua obra. A Sapê busca evitar excesso de informações que possam encarecer seu projeto. Abrimos espaço para que os fornecedores indiquem soluções que melhorem a qualidade e o preço do seu projeto."}
                                    />
                                    <MethodStep
                                        icon={<BiUserVoice className="icon"/>}
                                        title={"Suporte"}
                                        description={"Ao contratar a Sapê você está pagando pela assistência técnica sobre materiais, técnicas e serviços mesmo após a entrega do projeto. Caso seu fornecedor ou empreiteiro tenha alguma dúvida, poderemos atendê-lo sanando todas as questões para que seu projeto seja construído da melhor maneira possível."}
                                    />
                                </Carousel>
                                <div className="method-row desktop-only">
                                    <MethodStep
                                        icon={<AiOutlineControl className="icon"/>}
                                        title={"Etapa Visual"}
                                        description={"Começamos seu projeto de uma maneira didática e participativa onde compreendemos seus problemas e necessidades e te apresentamos um resultado fácil de entender, através de imagens, modelos tridimensionais e referências. É nessa etapa que você visualiza a cara e as soluções do nosso projeto."}
                                    />
                                    <MethodStep
                                        icon={<FaCalendarAlt className="icon"/>}
                                        title={"Etapa Técnica"}
                                        description={"Com base no projeto aprovado por você, realizamos os desenhos técnicos de modo simples e assertivo com as informações fundamentais para que possamos orçar com precisão sua obra. A Sapê busca evitar excesso de informações que possam encarecer seu projeto. Abrimos espaço para que os fornecedores indiquem soluções que melhorem a qualidade e o preço do seu projeto."}
                                    />
                                    <MethodStep
                                        icon={<BiUserVoice className="icon"/>}
                                        title={"Suporte"}
                                        description={"Ao contratar a Sapê você está pagando pela assistência técnica sobre materiais, técnicas e serviços mesmo após a entrega do projeto. Caso seu fornecedor ou empreiteiro tenha alguma dúvida, poderemos atendê-lo sanando todas as questões para que seu projeto seja construído da melhor maneira possível."}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div> 
        </div>
    )
}
export default Method;