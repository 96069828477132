import React from 'react';
import { GrMail } from "react-icons/gr";
import { AiOutlineMessage } from "react-icons/ai";

const Contact = () => {
    return (
        <div className="rn-contact-area rn-section-gapBottom" id="contact"> 
            <div className="wrapper">
                <div className="container">
                    <div className='contact-row'>
                        <div className="contact-info-inner">
                            <div className="section-title mb--60 mb_sm--40 mb_md--40">
                                <h3 className="title">Fale Conosco!</h3>
                            </div>
                            <div className="inner">
                                <div className="contact-info">
                                    <AiOutlineMessage className="icon"/>
                                    <h3 className="title"><a href="tel:+5511972656058">+55 (11) 9 7265-6058</a></h3>
                                </div>
                                <div className="contact-info">
                                    <GrMail className="icon"/>
                                    <h3 className="title"><a href="mailto:example@gmail.com">contato@sapearquitetos.com.br</a></h3>
                                </div>
                            </div>
                            <div className='contact-btn-container'>
                                <div className='inner'>
                                    <div className='whats-btn-box'>
                                        <p className='whats-btn-text' onClick={() => {window.open("https://wa.me/5511972656058", "_blank");}}>CHAMA A GENTE NO WHATS</p>
                                    </div>
                                    <div className='forms-btn-box'>
                                        <p className='forms-btn-text' onClick={() => {window.open("https://docs.google.com/forms/d/1O-QpqMFeiA_W9KvRu5XeRjZ1HgQAD_ji2rx-vadHhKU/viewform?edit_requested=true", "_blank");}}>
                                            RECEBA UM ORÇAMENTO
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
