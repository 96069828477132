import React, { useState, useEffect } from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import { window } from "browser-monads";
import { Carousel } from 'react-responsive-carousel';
import Projectcard from "./projectcard";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa"

const arrowStyles = {
    position: 'absolute',
    zIndex: 3,
    top: 'calc(50% - 25px)',
    width: 50,
    height: 50,
    cursor: 'pointer',
    color: "white"
};

const ProjectOne = () => {
    const ProjectData = useStaticQuery(graphql`
        query ProjectDataQuery {
            allProjectJson(limit: 20) {
                edges {
                    node {
                        id
                        title
                        category
                        priority
                        size
                        location
                        featured_image {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const projectsData = ProjectData.allProjectJson.edges;

    return (
        <div className="row row--25 project-carousel-wrapper">
            <Carousel 
                centerMode={true} 
                showThumbs={false} 
                showStatus={false} 
                infiniteLoop  
                centerSlidePercentage={40} 
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    <div onClick={onClickHandler} style={{position: "absolute", left: 0, width: "100px", height: "100%", zIndex: 2, cursor: 'pointer'}}>
                        <FaChevronCircleLeft style={{ ...arrowStyles, left: 25 }}/>
                    </div>
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    <div onClick={onClickHandler} style={{width: "100px", height: "100%", cursor: 'pointer', position: "absolute", top: 0, right: 0}}>
                        <FaChevronCircleRight style={{ ...arrowStyles, right: 25 }}/>
                    </div>
                }
                className='desktop-only' 
                preventMovementUntilSwipeScrollTolerance={true} 
                swipeScrollTolerance={50}>
                {
                    projectsData.sort((a, b) => a.priority - b.priority).map( data => (
                        <Projectcard 
                            key={data.node.id}
                            portfolioStyle="portfolio-style-1"
                            id={data.node.id}
                            image={data.node.featured_image?.childImageSharp}
                            title={data.node.title}
                            category={data.node.category}
                            size={data.node.size}
                            location={data.node.location}
                        />
                    ))
                }
            </Carousel>
            <Carousel 
                centerMode={false} 
                showThumbs={false} 
                showStatus={false} 
                infiniteLoop 
                className='mobile-only' 
                preventMovementUntilSwipeScrollTolerance={true} 
                swipeScrollTolerance={50}>
                {
                    projectsData.sort((a, b) => a.priority - b.priority).map( data => (
                        <Projectcard 
                            key={data.node.id}
                            portfolioStyle="portfolio-style-1"
                            id={data.node.id}
                            image={data.node.featured_image?.childImageSharp}
                            title={data.node.title}
                            category={data.node.category}
                            size={data.node.size}
                            location={data.node.location}
                        />
                    ))
                }
            </Carousel>
        </div>
    )
}

export default ProjectOne;