import React, { Component } from 'react';

class MethodStep extends Component {
    render() {
        return (
            <div className='method-card'>
                {this.props.icon}
                <p className="method-title">{this.props.title}</p>
                <p className="method-description">{this.props.description}</p>
            </div>
        )
    }
    
}

export default MethodStep;